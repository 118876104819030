import { Box, ButtonPrimary, HighlightedCard, ResponsiveLayout, Stack } from '@telefonica/mistica';
import { useNavigate } from 'react-router-dom';

import { useEvents } from '../../hooks/webapp-events/useEvents';
import { useRouterLogic } from '../../routes/useRouterLogic';
import { COOKIES_KEYS, getCookie, isWebview } from '../../util/functions';

import { DesktopHomeBanner, JweBox, MobileHomeBanner } from './components/home.styles';
import { SorteiosComponent } from './components/sorteios';
// import { PremioInstComponent } from './components/premios';
// import { RecargasHome } from './components/recargas';

const Home = () => {
  const { sendEvents } = useEvents();
  // const { homeInfo, isFetchingHomeInfo } = useGetHomeInfo();
  const navigate = useNavigate();

  const { jweFromQueryParam } = useRouterLogic();
  /* useEffect(() => {
    if (!isFetchingHomeInfo) {
      sendEvents({
        eventType: 'HOME_PAGE_ACCESS',
        triggerType: 'ACCESS',
        eventCategory: isWebview ? 'webview' : 'web',
        platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
      });
    }
  }, [isFetchingHomeInfo]);

  if (isFetchingHomeInfo) {
    return <LoadingRecargaPremiada visible={true}></LoadingRecargaPremiada>;
  }*/

  return (
    <Box>
      <JweBox>JWE: {jweFromQueryParam}</JweBox>
      <DesktopHomeBanner></DesktopHomeBanner>
      <MobileHomeBanner
        src="/apiCachefly/brasil/vivo/roblox/assets/home-banner-sm-v2.1.png"
        alt="banner"
      />
      {/*<div className="antifraud ">
        <Text2 regular color={skinVars.colors.textPrimaryInverse}>
          A Vivo não solicita que você faça depósitos/Pix para receber recompensas.
        </Text2>
      </div>*/}
      <ResponsiveLayout>
        <Stack space={'between'}>
          <Box paddingY={24}>
            <HighlightedCard
              title="Promoção encerrada!"
              description="Confira os ganhadores no site."
              isInverse
              button={
                <ButtonPrimary
                  small
                  onPress={() => {
                    sendEvents({
                      eventType: 'BUTTON_CLICK',
                      triggerType: 'CLICK',
                      subSource: 'como-funciona',
                      subSubSource: 'Ganhadores',
                      eventCategory: isWebview ? 'webview' : 'web',
                      platformType: getCookie(COOKIES_KEYS.PLATFORM_TYPE),
                    });
                    navigate('/draws#new-winners');
                  }}
                >
                  Ganhadores
                </ButtonPrimary>
              }
            />
          </Box>
          {/*<RecargasHome homeInfo={homeInfo}></RecargasHome>*/}
          {/*<PremioInstComponent tokens={homeInfo.userAvailableTokens}></PremioInstComponent>*/}
          <SorteiosComponent></SorteiosComponent>
        </Stack>
      </ResponsiveLayout>
    </Box>
  );
};

export default Home;
