import { COOKIES_KEYS, getCookie } from '../functions';

const baseUrl: string =
  window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
const proxyPath = `${baseUrl}/beapi`;
const eventsProxyPath = `${baseUrl}/event`;
const decryptProxy = `${baseUrl}/beAuth`;
const webviewAuth = `${baseUrl}/beWebviewAuth`;

export const Endpoints = {
  resetPin: `${proxyPath}/user/pin/send`,
  validatePin: `${proxyPath}/user/pin/validate`,
  getDrawList: `${proxyPath}/draw/listV2`,
  getTicketsByDraw: `${proxyPath}/draw/getPartDrawTickets`,
  getRedeemHistory: `${proxyPath}/prizetoken/redeem/historyV2`,
  getPrizeList: `${proxyPath}/prize/listV2`,
  getHomeInfo: `${proxyPath}/home/infoV2`,
  getWinnerInfo: `${proxyPath}/winner/info`,
  getWinnerInfoV2: `${proxyPath}/winner/infoV2`,
  postRedeem: `${proxyPath}/prizetoken/redeemV2`,
  topupHistory: `${proxyPath}/topup/historyV2`,
  postGiveTicket: `${proxyPath}/draw/give/ticket`,
  getPing: `${proxyPath}/ping`,
  events: `${eventsProxyPath}`,
  decryptMec: `${decryptProxy}/user/decrypt`,
  jweAuth: `${webviewAuth}/admin/auth`,
};

// export const serviceId = '170922';
export const serviceId = '189522';
export const operatorId = 1;

export const getHeadersWithBasic = (msisdn = '') => {
  const header = new Headers();
  if (Boolean(msisdn)) {
    header.append('msisdn', msisdn);
  }
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('Authorization', `Basic dGltd2Vyb2Jsb3g6d2tTZmhXVHBEM3Y0SFN5WA==`);
  return header;
};

export const getHeaders = () => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('Authorization', `Bearer ${getCookie(COOKIES_KEYS.ACCESS_TOKEN)}`);
  return header;
};
export const getHeadersTicket = (drawId: number) => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('drawId', drawId.toString());
  header.append('Authorization', `Bearer ${getCookie(COOKIES_KEYS.ACCESS_TOKEN)}`);
  return header;
};
export const getHeadersHome = () => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('Authorization', `Bearer ${getCookie(COOKIES_KEYS.ACCESS_TOKEN)}`);
  header.append('channel', '');
  return header;
};
export const getHeadersEvents = () => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('Access-Control-Allow-Origin', '*');
  return header;
};

export const getHeadersDecrypt = (mec: string) => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('Authorization', `Basic dGltd2Vyb2Jsb3g6d2tTZmhXVHBEM3Y0SFN5WA==`);
  header.append('mec', mec);
  return header;
};

export const getHeadersJWEAuth = (jwe: string) => {
  const header = new Headers();
  header.append('Content-type', 'application/json');
  header.append('serviceId', serviceId);
  header.append('Authorization', `Basic dGltd2Vyb2Jsb3g6d2tTZmhXVHBEM3Y0SFN5WA==`);
  header.append('jwe', jwe);
  return header;
};

export const recargaUrlMobile = `https://web.vivo.com.br/link/topup`;
export const recargaUrlWeb = `https://recarga.vivo.com.br/recarga?id_origem_vivo=b2c_ecommerce_portal-home_selecione_home_campanha_recarga_premiada_lp_game&origin=b2c_ecommerce_portal-home_selecione_home_campanha_recarga_premiada_lp_game`;
export const cookiesPolicyUrl = `/apiCachefly/brasil/vivo/roblox/documentos_legais/politica-de-cookies.pdf`;
export const privacyPolicyUrl = `/apiCachefly/brasil/vivo/roblox/documentos_legais/politica-de-privacidade-2022.pdf`;

export const timeZone: Intl.DateTimeFormatOptions = {
  timeZone: 'America/Sao_Paulo',
};

const monthFormatOptions: Intl.DateTimeFormatOptions = { month: 'long', ...timeZone };

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  ...timeZone,
};

type FormatDateType = 'month-long' | 'day-month-year-numeric';

const dateFormats = new Map<FormatDateType, Intl.DateTimeFormat>([
  ['month-long', new Intl.DateTimeFormat('pt-BR', monthFormatOptions)],
  ['day-month-year-numeric', new Intl.DateTimeFormat('pt-BR', dateFormatOptions)],
]);

export const formatDate = (date: number, formatType: FormatDateType) =>
  dateFormats.get(formatType)?.format(date) ?? '';

const getMonthLongByOffset = (offset = 0): string => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() + offset);
  return dateFormats.get('month-long')?.format(date) ?? '';
};

export const getCurrentMonthLong = (): string => getMonthLongByOffset();

export const getNextMonthLong = (): string => getMonthLongByOffset(1);

export const getLastMonthLong = (): string => getMonthLongByOffset(-1);

export const getMonthLong = (monthNumber: number): string => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return dateFormats.get('month-long')?.format(date) ?? '';
};
