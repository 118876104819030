import { ReactElement, useEffect } from 'react';
import { Box } from '@telefonica/mistica';

import { LoadingRecargaPremiada } from '../components/loading/loading';
import { COOKIES_KEYS, deleteCookie, setCookie } from '../util/functions';

import { useRouterLogic } from './useRouterLogic';

type RouteGuardProps = {
  children: ReactElement;
  path: string;
  publicRoute: boolean;
};

const RouteGuard = ({ children, path, publicRoute }: RouteGuardProps) => {
  const {
    setPath,
    setMec,
    setIsFetching,
    setJWE,
    navigateToLoginWithParams,
    isFetching,
    mecFromQueryParam,
    jweFromQueryParam,
    hasAuthParameter,
    hasAccessToken,
    pathFromQueryParam,
  } = useRouterLogic();

  useEffect(() => {
    // if (hasAuthParameter && !isFetching && !hasAccessToken) {
    if (hasAuthParameter && !isFetching) {
      setIsFetching(true);
      deleteCookie(COOKIES_KEYS.IS_WEBVIEW);
      deleteCookie(COOKIES_KEYS.PLATFORM_TYPE);
      setPath(Boolean(pathFromQueryParam) ? `/${pathFromQueryParam}` : path);
      setCookie(COOKIES_KEYS.IS_WEBVIEW, 'true');
      const paramValue = encodeURIComponent(mecFromQueryParam || jweFromQueryParam);
      Boolean(mecFromQueryParam) ? setMec(paramValue) : setJWE(paramValue);
    }
  });
  if (hasAccessToken || path.match('login') || (publicRoute && !hasAuthParameter)) {
    return children;
  }

  if (!publicRoute && !hasAuthParameter && !isFetching) {
    setTimeout(() => navigateToLoginWithParams(), 1);
  }
  return (
    <>
      <Box>MEC: {mecFromQueryParam}</Box>
      <LoadingRecargaPremiada visible={true}></LoadingRecargaPremiada>;
    </>
  );
};

export default RouteGuard;
