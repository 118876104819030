import { useCallback, useEffect, useState } from 'react';

import { Endpoints, getCurrentMonthLong, getHeadersHome } from '../util/constants';
import { COOKIES_KEYS, getCookie, Logout, setCookie } from '../util/functions';

export interface ProgressionStatus {
  dateKey: string;
  gamingProgressionName: string;
  status: string;
  cacheFlyUrl: string;
}

export interface DrawInfoTo {
  drawName: string;
  drawImgUrl: string;
  drawStartDate: number;
  drawGroup: number;
  drawEndDate: number;
  drawDate: number;
  daysLeft: number;
}

export interface ErrorTo {
  code: number;
  description: string;
  isError: boolean;
}

export interface GetHomeInfoResponse {
  userAvailableTokens: number;
  accumulatedMonthlyTickets: number;
  accumulatedWeeklyTickets: number;
  accumulatedTopUps: number;
  appTickets: number;
  prizeTier: number;
  isFirstLogin: boolean;
  lastTopupValue: string;
  progressionStatus: ProgressionStatus[];
  drawInfoTO: DrawInfoTo[];
  errorTO: ErrorTo;
}

export const GET_HOME_INFO_RESPONSE_INITIAL_STATE = {
  userAvailableTokens: 0,
  accumulatedMonthlyTickets: 0,
  accumulatedWeeklyTickets: 0,
  accumulatedTopUps: 0,
  appTickets: 0,
  prizeTier: 0,
  isFirstLogin: false,
  lastTopupValue: '',
  progressionStatus: [],
  drawInfoTO: [],
  errorTO: {
    code: 0,
    description: '',
    isError: false,
  },
};

export const useGetHomeInfo = () => {
  const [homeInfo, setHomeInfo] = useState<GetHomeInfoResponse>(
    GET_HOME_INFO_RESPONSE_INITIAL_STATE,
  );
  const [qtyFailedMissions, setQtyFailedMissions] = useState<number>(0);
  const [hasAllMissionsCompleted, sethasAllMissionsCompleted] = useState<boolean>(false);
  const [currentMonthMissionCompleted, setCurrentMonthMissionCompleted] = useState<boolean>(false);
  const [isFetchingHomeInfo, setIsFetchingHomeInfo] = useState<boolean>(true);
  const { logoutApp } = Logout();

  const [platformType, setPlatformType] = useState<string>('');

  const getHomeInfo = useCallback(() => {
    setIsFetchingHomeInfo(true);
    return fetch(Endpoints.getHomeInfo, { headers: getHeadersHome() })
      .then((response) => response.json())
      .then((json) => {
        setHomeInfo(json);
        return json;
      })
      .catch((error) => {
        console.error('GetHomeInfo', error);
        // logoutApp();
      })
      .finally(() => setIsFetchingHomeInfo(false));
  }, [logoutApp]);

  useEffect(() => {
    if (
      homeInfo === GET_HOME_INFO_RESPONSE_INITIAL_STATE &&
      Boolean(getCookie(COOKIES_KEYS.ACCESS_TOKEN))
    ) {
      getHomeInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (homeInfo !== GET_HOME_INFO_RESPONSE_INITIAL_STATE) {
      const { progressionStatus, isFirstLogin } = homeInfo;
      const currentMonth = getCurrentMonthLong().toLowerCase();

      const counts = progressionStatus.reduce(
        (acc, curr) => {
          const status = curr.status.toUpperCase();
          acc.failed += status === 'FAILED' ? 1 : 0;
          acc.completed += status === 'CONCLUDED' ? 1 : 0;
          return acc;
        },
        { failed: 0, completed: 0 },
      );

      const currentMonthMissionCompleted = progressionStatus.some(
        (progression) =>
          progression.status.toUpperCase() === 'CONCLUDED' &&
          progression.gamingProgressionName?.toLowerCase() === currentMonth,
      );

      setQtyFailedMissions(counts.failed);
      sethasAllMissionsCompleted(counts.completed >= 5);
      setCurrentMonthMissionCompleted(currentMonthMissionCompleted);

      if (!!isFirstLogin) {
        setCookie(COOKIES_KEYS.IS_FIRST_LOGIN, 'true');
      }
    }
  }, [homeInfo]);

  return {
    getHomeInfo,
    homeInfo,
    isFetchingHomeInfo,
    qtyFailedMissions,
    hasAllMissionsCompleted,
    currentMonthMissionCompleted,
    platformType,
    setPlatformType,
  };
};
